<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    BCard,
    BButton,
    BModal,
    BFormFile,
    BProgress,
    BFormCheckbox,
} from "bootstrap-vue";
import Web3 from "web3";
import { mapGetters, mapMutations } from "vuex";

export default {
    components: {
        BCard,
        BButton,
        BModal,
        BFormFile,
        BProgress,
        BFormCheckbox,
    },
    data() {
        return {
            pageArrayStock: [], // for Frontend Search
            pageArray: [],

            filterUserName: "",
            filterBendOnValue: "",

            transferResponse: null,
            transferObject: null,
            connectedAddr: null,

            finishText: "",

            errorMessage: "",
            ABI: [
                {
                    inputs: [],
                    stateMutability: "nonpayable",
                    type: "constructor",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "owner", type: "address" },
                        { indexed: true, internalType: "address", name: "spender", type: "address" },
                        { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
                    ],
                    name: "Approval",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "account", type: "address" },
                        { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                    ],
                    name: "EnableAccountStaking",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [{ indexed: false, internalType: "bool", name: "enabled", type: "bool" }],
                    name: "EnableStaking",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [{ indexed: false, internalType: "bool", name: "enabled", type: "bool" }],
                    name: "EnableSwapAndLiquify",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "account", type: "address" },
                        { indexed: false, internalType: "bool", name: "isExcluded", type: "bool" },
                    ],
                    name: "ExcludeFromFees",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "uint256", name: "newValue", type: "uint256" },
                        { indexed: true, internalType: "uint256", name: "oldValue", type: "uint256" },
                    ],
                    name: "GasForProcessingUpdated",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: "address",
                            name: "newLiquidityWallet",
                            type: "address",
                        },
                        {
                            indexed: true,
                            internalType: "address",
                            name: "oldLiquidityWallet",
                            type: "address",
                        },
                    ],
                    name: "LiquidityWalletUpdated",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: "address",
                            name: "previousOwner",
                            type: "address",
                        },
                        { indexed: true, internalType: "address", name: "newOwner", type: "address" },
                    ],
                    name: "OwnershipTransferred",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "iterations",
                            type: "uint256",
                        },
                        { indexed: false, internalType: "uint256", name: "claims", type: "uint256" },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "lastProcessedIndex",
                            type: "uint256",
                        },
                        { indexed: true, internalType: "bool", name: "automatic", type: "bool" },
                        { indexed: false, internalType: "uint256", name: "gas", type: "uint256" },
                        { indexed: true, internalType: "address", name: "processor", type: "address" },
                    ],
                    name: "ProcessedDividendTracker",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                        { indexed: false, internalType: "uint256", name: "opAmount", type: "uint256" },
                        { indexed: false, internalType: "bool", name: "success", type: "bool" },
                    ],
                    name: "SendDividends",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "pair", type: "address" },
                        { indexed: true, internalType: "bool", name: "value", type: "bool" },
                    ],
                    name: "SetAutomatedMarketMakerPair",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: false, internalType: "address", name: "wallet", type: "address" },
                    ],
                    name: "SetPreSaleWallet",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "tokensSwapped",
                            type: "uint256",
                        },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "ethReceived",
                            type: "uint256",
                        },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "tokensIntoLiqudity",
                            type: "uint256",
                        },
                    ],
                    name: "SwapAndLiquify",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "from", type: "address" },
                        { indexed: true, internalType: "address", name: "to", type: "address" },
                        { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
                    ],
                    name: "Transfer",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: false, internalType: "address", name: "token", type: "address" },
                        { indexed: false, internalType: "bool", name: "allow", type: "bool" },
                    ],
                    name: "UpdateAllowTokens",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "newAddress", type: "address" },
                        { indexed: true, internalType: "address", name: "oldAddress", type: "address" },
                    ],
                    name: "UpdateDividendTracker",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "operationsSellFee",
                            type: "uint256",
                        },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "operationsBuyFee",
                            type: "uint256",
                        },
                        { indexed: false, internalType: "uint256", name: "liquidity", type: "uint256" },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "BENDRewardsBuy",
                            type: "uint256",
                        },
                        {
                            indexed: false,
                            internalType: "uint256",
                            name: "BENDRewardsSell",
                            type: "uint256",
                        },
                    ],
                    name: "UpdateFees",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: false, internalType: "address", name: "account", type: "address" },
                        { indexed: false, internalType: "address", name: "token", type: "address" },
                    ],
                    name: "UpdatePayoutToken",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: "address",
                            name: "contractAddress",
                            type: "address",
                        },
                    ],
                    name: "UpdateStakingAddress",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: false, internalType: "uint256", name: "duration", type: "uint256" },
                        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                    ],
                    name: "UpdateStakingAmounts",
                    type: "event",
                },
                {
                    anonymous: false,
                    inputs: [
                        { indexed: true, internalType: "address", name: "newAddress", type: "address" },
                        { indexed: true, internalType: "address", name: "oldAddress", type: "address" },
                    ],
                    name: "UpdateUniswapV2Router",
                    type: "event",
                },
                {
                    inputs: [],
                    name: "BENDRewardsBuyFee",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "BENDRewardsSellFee",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "_contractAddress", type: "address" }],
                    name: "UpdatestakeAddress",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "owner", type: "address" },
                        { internalType: "address", name: "spender", type: "address" },
                    ],
                    name: "allowance",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "spender", type: "address" },
                        { internalType: "uint256", name: "amount", type: "uint256" },
                    ],
                    name: "approve",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "", type: "address" }],
                    name: "automatedMarketMakerPairs",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "balanceOf",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "buyAmount",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "claim",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "decimals",
                    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "spender", type: "address" },
                        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
                    ],
                    name: "decreaseAllowance",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "dividendTokenBalanceOf",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "dividendTracker",
                    outputs: [
                        { internalType: "contract BENDDividendTracker", name: "", type: "address" },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "enable", type: "bool" }],
                    name: "enableStaking",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "enabled", type: "bool" }],
                    name: "enableSwapAndLiquify",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "account", type: "address" },
                        { internalType: "bool", name: "excluded", type: "bool" },
                    ],
                    name: "excludeFromFees",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "forceStartTradingAt",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "tokens", type: "uint256" }],
                    name: "forceSwapAndSendDividends",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "gasForProcessing",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "getAccountDividendsInfo",
                    outputs: [
                        { internalType: "address", name: "", type: "address" },
                        { internalType: "int256", name: "", type: "int256" },
                        { internalType: "int256", name: "", type: "int256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
                    name: "getAccountDividendsInfoAtIndex",
                    outputs: [
                        { internalType: "address", name: "", type: "address" },
                        { internalType: "int256", name: "", type: "int256" },
                        { internalType: "int256", name: "", type: "int256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "token", type: "address" }],
                    name: "getAllowTokens",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "getLastProcessedIndex",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "getNumberOfDividendTokenHolders",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "getPayoutToken",
                    outputs: [{ internalType: "address", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "getStakingInfo",
                    outputs: [
                        { internalType: "uint256", name: "", type: "uint256" },
                        { internalType: "uint256", name: "", type: "uint256" },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "getTotalDividendsDistributed",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "spender", type: "address" },
                        { internalType: "uint256", name: "addedValue", type: "uint256" },
                    ],
                    name: "increaseAllowance",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "isExcludedFromAutoClaim",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "isExcludedFromFees",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "isReinvest",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "liquidityFee",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "liquidityWallet",
                    outputs: [{ internalType: "address", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "maxSellTransactionAmount",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "name",
                    outputs: [{ internalType: "string", name: "", type: "string" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "operations1Address",
                    outputs: [{ internalType: "address payable", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "operations2Address",
                    outputs: [{ internalType: "address payable", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "operationsBuyFee",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "operationsSellFee",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "owner",
                    outputs: [{ internalType: "address", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "gas", type: "uint256" }],
                    name: "processDividendTracker",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "uint256", name: "amount", type: "uint256" },
                        { internalType: "address payable", name: "receiver", type: "address" },
                    ],
                    name: "recoveryBalance",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "renounceOwnership",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "sellAmount",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "allow", type: "bool" }],
                    name: "setAllowAutoReinvest",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "allow", type: "bool" }],
                    name: "setAllowCustomTokens",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "value", type: "bool" }],
                    name: "setAutoClaim",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "pair", type: "address" },
                        { internalType: "bool", name: "value", type: "bool" },
                    ],
                    name: "setAutomatedMarketMakerPair",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "value", type: "bool" }],
                    name: "setDividendsPaused",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "wallet", type: "address" }],
                    name: "setPresaleWallet",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "bool", name: "value", type: "bool" }],
                    name: "setReinvest",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "duration", type: "uint256" }],
                    name: "stake",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "stakingAddress",
                    outputs: [{ internalType: "address", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    name: "stakingAmounts",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "", type: "address" }],
                    name: "stakingBonus",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "", type: "address" }],
                    name: "stakingUntilDate",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "swapAndLiquifyEnabled",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "swapTokensAtAmount",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "symbol",
                    outputs: [{ internalType: "string", name: "", type: "string" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "totalSupply",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "recipient", type: "address" },
                        { internalType: "uint256", name: "amount", type: "uint256" },
                    ],
                    name: "transfer",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "sender", type: "address" },
                        { internalType: "address", name: "recipient", type: "address" },
                        { internalType: "uint256", name: "amount", type: "uint256" },
                    ],
                    name: "transferFrom",
                    outputs: [{ internalType: "bool", name: "", type: "bool" }],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
                    name: "transferOwnership",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "uniswapV2Pair",
                    outputs: [{ internalType: "address", name: "", type: "address" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [],
                    name: "uniswapV2Router",
                    outputs: [
                        { internalType: "contract IUniswapV2Router02", name: "", type: "address" },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "token", type: "address" },
                        { internalType: "bool", name: "allow", type: "bool" },
                    ],
                    name: "updateAllowTokens",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "newAddress", type: "address" }],
                    name: "updateDividendTracker",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "uint256", name: "operationsSellTax", type: "uint256" },
                        { internalType: "uint256", name: "operationsBuyTax", type: "uint256" },
                        { internalType: "uint256", name: "liquidityTax", type: "uint256" },
                        { internalType: "uint256", name: "BENDRewardsBuyTax", type: "uint256" },
                        { internalType: "uint256", name: "BENDRewardsSellTax", type: "uint256" },
                    ],
                    name: "updateFees",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "newValue", type: "uint256" }],
                    name: "updateGasForProcessing",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address", name: "newLiquidityWallet", type: "address" },
                    ],
                    name: "updateLiquidityWallet",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "newNum", type: "uint256" }],
                    name: "updateMaxAmount",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address payable", name: "newAddress", type: "address" },
                    ],
                    name: "updateOperations1Address",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "address payable", name: "newAddress", type: "address" },
                    ],
                    name: "updateOperations2Address",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "token", type: "address" }],
                    name: "updatePayoutToken",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [
                        { internalType: "uint256", name: "duration", type: "uint256" },
                        { internalType: "uint256", name: "bonus", type: "uint256" },
                    ],
                    name: "updateStakingAmounts",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "uint256", name: "newNum", type: "uint256" }],
                    name: "updateSwapTokensAtAmount",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "newAddress", type: "address" }],
                    name: "updateUniswapV2Router",
                    outputs: [],
                    stateMutability: "nonpayable",
                    type: "function",
                },
                {
                    inputs: [{ internalType: "address", name: "account", type: "address" }],
                    name: "withdrawableDividendOf",
                    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                    stateMutability: "view",
                    type: "function",
                },
                {
                    stateMutability: "payable",
                    type: "receive",
                },
            ],
            contractAddr: "0x13abec309d958c7900e8dea7d930d794981507ad"

        };
    },
    async created() {
        await this.mountedData();
    },
    computed: {
        ...mapGetters("web3", ["getInstance"]),
        web3() {
            return this.getInstance;
        },
    },
    methods: {
        ...mapMutations("web3", ["registerWeb3Instance"]),
        async metaMaskMethod(type) {

            let instance, contract, decimals, metamask, provider;

            const isMetaMaskInstalled = () => {
                return Boolean(window.ethereum);
            }

            const PROVIDER = "https://bsc-dataseed.binance.org/";


            if (isMetaMaskInstalled()) {
                metamask = new this.$ethers.providers.Web3Provider(window.ethereum, "any");
                provider = new this.$ethers.providers.JsonRpcProvider(PROVIDER);
            } else {
                return this.$bvModal.show('bv-modal-metamask')
            }
            
            // Connected olmadıysa connect olmasını iste
            const connectToWallet = async () => {
                if (isMetaMaskInstalled()) {
                    await metamask.send("eth_requestAccounts", []);
                    await init();
                }
            }

            const init = async () => {
                instance = await metamask.getSigner();
                contract = new this.$ethers.Contract(this.contractAddr, this.ABI, instance);
                this.connectedAddr = await instance.getAddress();
                decimals = await contract.decimals();
            }


            if (type == 'connect') {
                await connectToWallet();
            }

            if (type == 'transfer') {
                await connectToWallet();
                const realTransferValue = this.$ethers.utils.parseUnits(
                    this.transferObject.bendOnValue.toString(),
                    decimals
                );

                try {
                    this.$bvModal.show('bv-modal-check')
                    const tx = await contract.transfer(this.transferObject.userWalletId, realTransferValue);
                    this.$bvModal.show('bv-modal-ready')
                    this.$bvModal.hide('bv-modal-check')
                    const response = await tx.wait();
                    if (response.status == 1) {
                        await this.$http({
                            method: "POST",
                            url: "user/updateWallet",
                            data: {
                                thisId: this.transferObject.thisId,
                                finishText: 'Payment complete',
                                isStatus: 1
                            },
                            headers: {
                                "Content-Type": "application/json;charset=utf-8",
                            },
                        })
                            .then(response => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success, Please Wait..",
                                        icon: "CheckIcon",
                                        variant: "success",
                                    },
                                });
                                this.$bvModal.hide('bv-modal-ready')
                                this.$bvModal.show('bv-modal-successful')
                                setTimeout(() => {
                                    this.$bvModal.hide('bv-modal-successful')
                                }, 3000);
                                this.mountedData();
                            })
                            .catch((error) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "ERROR",
                                        icon: "XIcon",
                                        variant: "danger",
                                    },
                                });
                            });
                    } else {
                        this.transferResponse = response
                        this.$bvModal.show('bv-modal-decline')
                        this.$bvModal.hide('bv-modal-check')
                    }
                } catch (e) {
                    this.$bvModal.hide('bv-modal-check')
                    e.code == 'ACTION_REJECTED' ? this.$bvModal.show('bv-modal-rejected') : '--'
                    e.code == 'UNPREDICTABLE_GAS_LIMIT' ? this.$bvModal.show('bv-modal-amount') : '--'
                }
            }

        },
        async mountedData() {
            // Clear Variables..
            this.filterName = "";
            this.filterBendOnValue = "";

            // Get List
            await this.$http
                .get("user/transaction/list/0")
                .then((response) => {
                    this.pageArrayStock = this.pageArray = response.data;
                })
                .catch(() => {
                    alert("ERROR! PLEASE CONTACT TO DEVELOPER");
                });
        },
        async tableInteractive(item, index, type) {
            if ("pay") {
                this.transferObject = structuredClone(item)
            }
        },
        async saveData(type, item) {
            if (type === "report") {
                await this.$http({
                    method: "POST",
                    url: "/bend/user/updateWallet",
                    data: {
                        thisId: this.transferObject.thisId,
                        finishText: this.finishText,
                        isStatus: 2
                    },
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                })
                    .then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Success, Please Wait..",
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                        this.mountedData();
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "ERROR",
                                icon: "XIcon",
                                variant: "danger",
                            },
                        });
                    });
            }
        },
        async filterNow() {
            this.pageArray = this.pageArrayStock.filter(
                (x) =>
                    x.user.userUsername
                        .toString()
                        .toLocaleLowerCase()
                        .includes(this.filterUserName.toLocaleLowerCase()) &&
                    x.bendOnValue
                        .toString()
                        .toLocaleLowerCase()
                        .includes(this.filterBendOnValue.toLocaleLowerCase())
            );
        },
    },
    watch: {
        filterUserName: {
            handler() {
                this.filterNow();
            },
        },
        filterBendOnValue: {
            handler() {
                this.filterNow();
            },
        },
    },
};
</script>

<template>
    <section class="pb-w">
        <div class="tb-card tb-flex-vertical">
            <a class="tb-page-title">Pay Request</a>
            <button @click="metaMaskMethod('connect')"
                class="d-flex flex-row align-items-center justify-content-center tb-button bg-primary ml-auto">
                <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" alt="Metamask"
                    style="width: 30px" />
                <span class="text-white pl-1">{{
                connectedAddr ? "Connected" : "Connect to Metamask"
                }}</span>
            </button>
        </div>
        <div class="tb-card mt-2">
            <table class="tb-table">
                <thead>
                    <tr>
                        <th style="width: 5%">
                            <input type="text" class="form-control" placeholder="#" disabled readonly />
                        </th>
                        <th style="width: 15%">
                            <input type="text" class="form-control" placeholder="User Name" v-model="filterUserName" />
                        </th>
                        <th style="width: 20%">
                            <input type="text" class="form-control" placeholder="Bend On Value"
                                v-model="filterBendOnValue" />
                        </th>
                        <th style="width: 20%">
                            <input type="text" class="form-control" placeholder="Created At" disabled readonly />
                        </th>
                        <th style="width: 20%">
                            <input type="text" class="form-control" placeholder="Options" disabled readonly />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in pageArray">
                        <td>
                            <a>{{ index + 1 }}</a>
                        </td>
                        <td>
                            <a>{{ item.user.userUsername }}</a>
                        </td>
                        <td>
                            <a>{{ item.bendOnValue }}</a>
                        </td>
                        <td>
                            <a>{{ $moment(new Date(item.createdAt)).format("YYYY-MM-DD") }}</a>
                        </td>
                        <td>
                            <a class="tb-button bg-primary px-2" target="_blank" :href="`https://bscscan.com/${
                              item.transactionId ? 'tx/' + item.transactionId : ''
                            }`">Detail</a>
                            <button class="tb-button bg-success mx-1 px-3" @click="$bvModal.show('bv-modal-pay'), tableInteractive(item, index, 'pay')
                            ">
                                <span class="text-white">Pay</span>
                            </button>
                            <button class="tb-button bg-danger px-3" @click="$bvModal.show('bv-modal-report'), tableInteractive(item, index, 'report')
                            ">
                                <span class="text-white">Report</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Pay -->
        <b-modal centered id="bv-modal-pay" hide-header hide-footer>
            <div class="d-block text-center mt-2">Are you sure you want to Pay ?</div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 btn-danger mx-1" block @click="$bvModal.hide('bv-modal-pay')">Cancel
                </button>
                <button class="tb-button col-6 mt-3 bg-primary mx-1" block
                    @click="$bvModal.hide('bv-modal-pay'), metaMaskMethod('transfer')">Pay
                </button>
            </div>
        </b-modal>

        <!-- Decline Not -->
        <b-modal centered id="bv-modal-decline" hide-header hide-footer>
            <div class="d-block text-center mt-2">Decline Not !</div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 bg-danger mx-1" block
                    @click="$bvModal.hide('bv-modal-decline'),$bvModal.show('bv-modal-report')">Report
                </button>
                <button class="tb-button col-6 mt-3 bg-danger mx-1" block
                    @click="$bvModal.hide('bv-modal-decline')">Close
                </button>
            </div>
        </b-modal>

        <!-- Transfer amount exceeds balance -->
        <b-modal centered id="bv-modal-amount" hide-header hide-footer>
            <div class="d-block text-center mt-2">Transfer amount exceeds balance !</div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 bg-primary mx-1" block @click="$bvModal.hide('bv-modal-amount')">OK
                </button>
            </div>
        </b-modal>

        <!-- Transaction in progress -->
        <b-modal centered id="bv-modal-ready" hide-header hide-footer no-close-on-backdrop>
            <div class="d-block text-center my-2">Transaction in progress... <br> Please wait !
            </div>
        </b-modal>

        <!-- Check Metamask -->
        <b-modal centered id="bv-modal-check" hide-header hide-footer no-close-on-backdrop>
            <div class="d-block text-center my-2">
                <h3>Please check Metamask !</h3>
            </div>
        </b-modal>
        <!-- Transaction Successful -->
        <b-modal centered id="bv-modal-successful" hide-header hide-footer no-close-on-backdrop>
            <div class="d-block text-center mt-2">Transaction successful !
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 bg-primary mx-1" block
                    @click="$bvModal.hide('bv-modal-successful')">OK
                </button>
            </div>
        </b-modal>


        <!-- cancel the transaction -->
        <b-modal centered id="bv-modal-rejected" hide-header hide-footer>
            <div class="d-block text-center mt-2">User rejected transaction !</div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 bg-primary mx-1" block
                    @click="$bvModal.hide('bv-modal-rejected')">OK
                </button>
            </div>
        </b-modal>

        <!-- Report -->
        <b-modal centered id="bv-modal-report" hide-header hide-footer>
            <h3 class="d-block text-center">
                Report !
            </h3>
            <textarea v-model="finishText" type="text" class="form-control mt-2" placeholder="Report.."
                style="resize:none"></textarea>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-3 bg-danger mx-1" block
                    @click="$bvModal.hide('bv-modal-report'), saveData('report')">Report
                </button>
            </div>
        </b-modal>

        <!-- Metamask Uninstall -->
        <b-modal centered id="bv-modal-metamask" hide-header hide-footer>
            <div class="text-center mt-2">
                <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" alt="Metamask"
                    style="width: 50px" />
                <p class="mt-1">Please add metasmask to chrome !</p>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button class="tb-button col-6 mt-2 bg-primary mx-1" block
                    @click="$bvModal.hide('bv-modal-metamask')">OK
                </button>
            </div>
        </b-modal>



    </section>
</template>

<style scoped>
.tb-button {
    text-decoration: none;
    color: white !important;
}
</style>
